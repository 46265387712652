const TIME_QUERIES = ['last_3', 'last_5', 'last_10', 'current_season', 'custom'];
const DEFAULT_TIME_QUERY = 'last_3';
const CLUB_FRIENDLY_ID = '5f34ef232b928b3223dd632d';

// Define game type filters in one centralized structure
const GAME_TYPE_FILTERS = [
  { value: 'all', label: 'All', gameTypes: [] },
  { value: 'pre-season', label: 'Pre Season', gameTypes: ['friendly'] },
  { value: 'regular-season', label: 'Regular', gameTypes: ['regular', 'relegation', null] },
  {
    value: 'post-season',
    label: 'Post Season',
    gameTypes: ['playoff', 'promotion', 'qualification'],
  },
  { value: 'cup', label: 'Cup', gameTypes: ['cup'] },
];

class GameStatsQuerySelector {
  private selectedSeasonId;
  private timeQueries;
  private timeQuery;
  private gameSelections;
  private selectedLeagueId;
  private selectedLeague;
  private locationFilter = 'all';
  private searchQuery = '';
  private selectAllGames = false;
  private selectedTimeOption;
  private teamId;

  private games;
  private leagues;

  private onChange;
  private filteredGames;
  private initialValue: any = {};

  private onlyPlayedGamesEnabled;
  private onlyPlayedGames;
  private gameTypeFilter = 'all';

  private hasPreSeasonGames = false;
  private hasRegularSeasonGames = false;
  private hasPostSeasonGames = false;
  private hasCupGames = false;

  private regularSeasonGamesCount = 0;

  constructor(
    private SeasonService,
    private $scope,
    private $mdMenu,
  ) {
    this.gameSelections = {};

    this.timeQueries = TIME_QUERIES;
    this.timeQuery = this.timeQuery || DEFAULT_TIME_QUERY;
    this.selectedTimeOption = this.timeQuery;
    this.selectedLeagueId = 'all';
    this.filteredGames = [];
    this.onlyPlayedGames = false;
  }

  $onInit() {
    this.$scope.$on('$mdMenuClose', this.onMenuClose);
    this.SeasonService.waitForSeason().then(() => {
      this.selectedSeasonId = this.SeasonService.getSeasonId();
      this.onChange({
        $seasonId: this.selectedSeasonId,
        $timeQuery: this.timeQuery || DEFAULT_TIME_QUERY,
      });
    });
  }

  $onChanges(changes) {
    if (changes.timeQuery) {
      this.timeQuery = changes.timeQuery.currentValue || DEFAULT_TIME_QUERY;
      this.selectedTimeOption = this.timeQuery;
    }
    if (changes.games) {
      this.filterGame();
    }
  }

  isFriendlyGame(game) {
    return game.gameType === 'friendly' || game.league?._id === CLUB_FRIENDLY_ID;
  }

  onMenuClose = () => {
    this.timeQuery = this.initialValue.timeQuery;
    this.gameSelections = this.initialValue.gameSelections;
    this.selectedLeagueId = this.initialValue.selectedLeagueId;
    this.selectedLeague = this.initialValue.selectedLeague;
    this.onlyPlayedGames = this.initialValue.onlyPlayedGames;
    this.gameTypeFilter = this.initialValue.gameTypeFilter;
    this.locationFilter = this.initialValue.locationFilter;
    this.searchQuery = this.initialValue.searchQuery;
    this.selectedTimeOption = this.initialValue.selectedTimeOption;
    this.filterGame();
  };

  showMenu($mdMenu) {
    // Store initial values for potential cancellation
    this.initialValue = {
      timeQuery: this.timeQuery,
      gameSelections: { ...this.gameSelections },
      selectedLeagueId: this.selectedLeagueId,
      selectedLeague: this.selectedLeague,
      onlyPlayedGames: this.onlyPlayedGames,
      gameTypeFilter: this.gameTypeFilter,
      locationFilter: this.locationFilter,
      searchQuery: this.searchQuery,
      selectedTimeOption: this.selectedTimeOption,
    };

    // We don't reset selections here anymore since we want to keep them
    // Location filter changes will still trigger resets in onLocationFilterChange

    $mdMenu.open();
  }

  onTimeOptionChange() {
    if (this.selectedTimeOption === 'custom') {
      this.timeQuery = 'custom';
      this.gameSelections = {};

      // Reset other filters to show all games initially
      this.gameTypeFilter = 'all';
      this.locationFilter = 'all';
      this.searchQuery = '';
    } else {
      this.timeQuery = this.selectedTimeOption;
      this.gameSelections = {};
    }

    this.filterGame();
  }

  filterGame() {
    if (!this.games) {
      this.filteredGames = [];
      this.regularSeasonGamesCount = 0;
      return;
    }

    this.hasPreSeasonGames = false;
    this.hasRegularSeasonGames = false;
    this.hasPostSeasonGames = false;
    this.hasCupGames = false;
    this.regularSeasonGamesCount = 0;

    this.games.forEach((g) => {
      const leagueFilterPassed =
        this.selectedLeagueId === 'all'
          ? true
          : this.selectedLeagueId === CLUB_FRIENDLY_ID
            ? g.league?._id === CLUB_FRIENDLY_ID || g.gameType === 'friendly'
            : g.league?._id === this.selectedLeagueId && g.gameType !== 'friendly';

      if (leagueFilterPassed) {
        const gameTypeFilter = GAME_TYPE_FILTERS.find(
          (filter) => filter.value === 'pre-season' && filter.gameTypes.includes(g.gameType),
        );
        if (gameTypeFilter) {
          this.hasPreSeasonGames = true;
        }

        const isRegularSeason = GAME_TYPE_FILTERS.find(
          (filter) => filter.value === 'regular-season' && filter.gameTypes.includes(g.gameType),
        );
        if (isRegularSeason || g.gameType === null) {
          this.hasRegularSeasonGames = true;
        }

        const isPostSeason = GAME_TYPE_FILTERS.find(
          (filter) => filter.value === 'post-season' && filter.gameTypes.includes(g.gameType),
        );
        if (isPostSeason) {
          this.hasPostSeasonGames = true;
        }

        const isCup = GAME_TYPE_FILTERS.find(
          (filter) => filter.value === 'cup' && filter.gameTypes.includes(g.gameType),
        );
        if (isCup) {
          this.hasCupGames = true;
        }
      }
    });

    if (
      (this.gameTypeFilter === 'pre-season' && !this.hasPreSeasonGames) ||
      (this.gameTypeFilter === 'regular-season' && !this.hasRegularSeasonGames) ||
      (this.gameTypeFilter === 'post-season' && !this.hasPostSeasonGames) ||
      (this.gameTypeFilter === 'cup' && !this.hasCupGames)
    ) {
      this.gameTypeFilter = 'all';
    }

    this.filteredGames = this.games.filter((g) => {
      const leagueFilterPassed =
        this.selectedLeagueId === 'all'
          ? true
          : this.selectedLeagueId === CLUB_FRIENDLY_ID
            ? g.league?._id === CLUB_FRIENDLY_ID || g.gameType === 'friendly'
            : g.league?._id === this.selectedLeagueId && g.gameType !== 'friendly';

      if (!leagueFilterPassed) {
        return false;
      }

      let gameTypeFilterPassed = true;
      if (this.gameTypeFilter !== 'all') {
        const selectedFilter = GAME_TYPE_FILTERS.find(
          (filter) => filter.value === this.gameTypeFilter,
        );
        if (selectedFilter) {
          gameTypeFilterPassed =
            selectedFilter.gameTypes.includes(g.gameType) ||
            (this.gameTypeFilter === 'regular-season' && g.gameType === null);
        }
      }

      if (!gameTypeFilterPassed) {
        return false;
      }

      if (this.locationFilter !== 'all' && this.teamId) {
        if (this.locationFilter === 'home' && g.home.scId !== this.teamId) {
          return false;
        }

        if (this.locationFilter === 'away' && g.away.scId !== this.teamId) {
          return false;
        }
      }

      if (this.searchQuery) {
        const searchTerm = this.searchQuery.toLowerCase();
        const homeTeam = (g.home.scName || g.home.name || '').toLowerCase();
        const awayTeam = (g.away.scName || g.away.name || '').toLowerCase();
        const gameDate = (g.date || '').toLowerCase();

        if (
          !homeTeam.includes(searchTerm) &&
          !awayTeam.includes(searchTerm) &&
          !gameDate.includes(searchTerm)
        ) {
          return false;
        }
      }

      return true;
    });

    this.regularSeasonGamesCount = this.games.filter((g) => {
      if (this.selectedLeagueId === 'all') {
        return true; // Count all games when "All" leagues is selected
      } else if (this.selectedLeagueId === CLUB_FRIENDLY_ID) {
        return g.league?._id === CLUB_FRIENDLY_ID || g.gameType === 'friendly';
      } else {
        return g.league?._id === this.selectedLeagueId && g.gameType !== 'friendly';
      }
    }).length;

    this.updateSelectAllState();
  }

  onGameTypeFilterChange() {
    this.filterGame();
  }

  onLocationFilterChange() {
    // Store previous location filter value to detect changes
    const prevLocationFilter = this.initialValue.locationFilter;

    // Only reset game selections if location filter has actually changed
    if (this.isCustomGamesSelected && prevLocationFilter !== this.locationFilter) {
      this.gameSelections = {};
    }

    this.filterGame();
  }

  onSearchQueryChange() {
    this.filterGame();
  }

  onLeagueSelectionChange() {
    this.filterGame();
    this.selectedLeague = this.leagues.find((l) => l._id === this.selectedLeagueId);
    this.gameSelections = {};
    if (!this.timeQuery && this.selectedTimeOption !== 'custom') {
      this.timeQuery = DEFAULT_TIME_QUERY;
      this.selectedTimeOption = DEFAULT_TIME_QUERY;
    }
  }

  toggleGameSelection(game) {
    this.gameSelections[game.gameId] = !this.gameSelections[game.gameId];
    this.updateSelectAllState();
  }

  onGameSelectionChange(_game) {
    this.updateSelectAllState();
  }

  toggleSelectAllGames() {
    const newState = this.selectAllGames;

    this.filteredGames.forEach((game) => {
      this.gameSelections[game.gameId] = newState;
    });
  }

  updateSelectAllState() {
    if (!this.filteredGames || this.filteredGames.length === 0) {
      this.selectAllGames = false;
      return;
    }

    const selectedCount = this.filteredGames.filter(
      (game) => this.gameSelections[game.gameId],
    ).length;

    this.selectAllGames = selectedCount === this.filteredGames.length;
  }

  apply() {
    this.onChange({
      $league: this.selectedLeagueId,
      $timeQuery: this.timeQuery === 'custom' ? null : this.timeQuery,
      $games: this.games.filter((g) => !!this.gameSelections[g.gameId]),
      $onlyPlayedGames: this.onlyPlayedGames,
      $gameTypeFilter: this.gameTypeFilter,
      $locationFilter: this.locationFilter,
    });
    this.$mdMenu.hide();
  }

  get isCustomGamesSelected() {
    return this.selectedTimeOption === 'custom';
  }

  get totalSelectedGames() {
    return Object.keys(this.gameSelections).filter((key) => !!this.gameSelections[key]).length;
  }

  getAvailableGameTypeFilters() {
    return GAME_TYPE_FILTERS.filter((filter) => {
      if (filter.value === 'all') return true;
      if (filter.value === 'pre-season') return this.hasPreSeasonGames;
      if (filter.value === 'regular-season') return this.hasRegularSeasonGames;
      if (filter.value === 'post-season') return this.hasPostSeasonGames;
      if (filter.value === 'cup') return this.hasCupGames;
      return false;
    });
  }
}

angular.module('app.general').component('gamestatsQuerySelector', {
  templateUrl: 'general/components/gamestats-query-selector.html',
  controller: GameStatsQuerySelector as any,
  bindings: {
    games: '<',
    leagues: '<',
    isDisabled: '<',
    timeQuery: '<',
    onlyPlayedGames: '<',
    onlyPlayedGamesEnabled: '<',
    onChange: '&',
    teamId: '<',
  },
});
